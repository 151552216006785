::-webkit-scrollbar {
  width: 7px;
}
  .connect {
    font-size: 16px;
    background-color: white;
    color: #911d3a;
    border: none;
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 20px;
    width: 100%;
    cursor: pointer;
  }

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4e4e4e;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b34f68;
}

body {
  overflow-x: hidden;
}

@media screen and (max-width: 499px) {
  .logo {
    cursor: pointer;
    width: 130px;
    animation: zoom 3s infinite;
    transform-origin: center;
    display: none;
  }
  
  .logoMob {
    cursor: pointer;
    width: 130px;
    animation: zoom 3s infinite;
    transform-origin: center;
  }

  .connect {
    font-size: 16px;
    background-color: white;
    color: #911d3a;
    border: none;
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 20px;
    width: 100%;
    cursor: pointer;
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }


  .footer {
    background-color: #1c2834;
    z-index: 100;
    padding-bottom: 15px;
    padding-top: 5px;
  }

  .page {
    display: flex;
    justify-content: center;
  }

  .sideBar {
    position: sticky;
    top: 0px;
    width: calc((160vw - 800px) * 0.45);
    border-right: 1px solid #911d3a;
    color: white;
    height: 100vh;
    display: none;
  }

  .footer {
    position: sticky;
    bottom: 0px;
    color: white;
    display: flex;
    flex-flow: row nowrap;
  }

  .rightBar {
    width: calc((115vw - 800px) * 0.55);
    border-left: 1px solid #911d3a;
    color: white;
    display: none;
  }

  a:-webkit-any-link {
    cursor: pointer;
    text-decoration: none !important;
    height: 0px !important;
    color: white;
  }

  .loginPage {
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .connect {
    font-size: 16px;
    background-color: white;
    color: #911d3a;
    border: none;
    padding: 8px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 20px;
    width: 100%;
    cursor: pointer;
  }

  .logo {
    cursor: pointer;
    width: 150px;
    animation: zoom 3s infinite;
    transform-origin: center;
    display: none;
  }

    
  .logoMob {
    cursor: pointer;
    width: 150px;
    animation: zoom 3s infinite;
    transform-origin: center;
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }


  .footer {
    background-color: #1c2834;
    z-index: 100;
    padding-bottom: 15px;
    padding-top: 5px;
  }

  .page {
    display: flex;
    justify-content: center;
  }

  .sideBar {
    position: sticky;
    top: 0px;
    width: calc((160vw - 800px) * 0.45);
    border-right: 1px solid #911d3a;
    color: white;
    height: 100vh;
    display: none;
  }

  .footer {
    position: sticky;
    bottom: 0px;
    color: white;
    display: flex;
    flex-flow: row nowrap;
  }

  .rightBar {
    width: calc((115vw - 800px) * 0.55);
    border-left: 1px solid #911d3a;
    color: white;
    display: none;
  }

  .miniWindow {}

  a:-webkit-any-link {
    cursor: pointer;
    text-decoration: none !important;
    height: 0px !important;
    color: white;
  }

  .loginPage {
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  
  .connect {
    display: none;
  }  

  .logoMob {
    display: none;
  }

  .logo {
    cursor: pointer;
    width: 180px;
    animation: zoom 3s infinite;
    transform-origin: center;
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }


  .page {
    display: flex;
    justify-content: space-between;
  }

  .sideBar {
    position: sticky;
    top: 0px;
    width: calc((160vw - 800px) * 0.45);
    border-right: 1px solid #911d3a;
    color: white;
    height: 100vh;
  }

  .rightBar {
    width: calc((115vw - 800px) * 0.55);
    border-left: 1px solid #911d3a;
    color: white;
  }

  .miniWindow {
    width: 100%;
  }

  a:-webkit-any-link {
    cursor: pointer;
    text-decoration: none !important;
    height: 0px !important;
    color: white;
  }

  .loginPage {
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }

}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .logo {
    cursor: pointer;
    width: 180px;
    animation: zoom 3s infinite;
    transform-origin: center;
  }

  .logoMob {
    display: none;
  }

  .connect {
    display: none;
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }


  .page {
    display: flex;
    justify-content: space-between;
  }

  .sideBar {
    position: sticky;
    top: 0px;
    width: calc((120vw - 800px) * 0.45);
    border-right: 1px solid #911d3a;
    color: white;
    height: 100vh;
  }

  .rightBar {
    width: calc((115vw - 800px) * 0.55);
    border-left: 1px solid #911d3a;
    color: white;
  }

  .miniWindow {
    width: 800px;
  }

  a:-webkit-any-link {
    cursor: pointer;
    text-decoration: none !important;
    height: 0px !important;
    color: white;
  }

  .loginPage {
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .logo {
    cursor: pointer;
    width: 180px;
    animation: zoom 3s infinite;
    transform-origin: center;
  }

  .logoMob {
    display: none;
  }
  .connect {
    display: none;
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .page {
    display: flex;
    justify-content: space-between;
  }

  .sideBar {
    position: sticky;
    top: 0px;
    width: calc((105vw - 800px) * 0.45);
    border-right: 1px solid #911d3a;
    color: white;
    height: 100vh;
  }

  .rightBar {
    width: calc((115vw - 800px) * 0.55);
    border-left: 1px solid #911d3a;
    color: white;
    height: 200vh;
    position: sticky;
    top: 0;
  }

  .miniWindow {
    width: 800px;
  }

  a:-webkit-any-link {
    cursor: pointer;
    text-decoration: none !important;
    height: 0px !important;
    color: white;
  }

  .loginPage {
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (min-width: 1920px) {
  .connect {
    display: none;
  }
  
  .logoMob {
    display: none;
  }

  .logo {
    cursor: pointer;
    width: 180px;
    animation: zoom 3s infinite;
    transform-origin: center;
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  .page {
    display: flex;
    justify-content: space-between;
  }

  .sideBar {
    position: sticky;
    top: 0px;
    width: calc((105vw - 800px) * 0.45);
    border-right: 1px solid #911d3a;
    color: white;
    height: 100vh;
  }

  .rightBar {
    width: calc((115vw - 800px) * 0.55);
    border-left: 1px solid #911d3a;
    color: white;
  }

  .miniWindow {
    width: 800px;
  }

  a:-webkit-any-link {
    cursor: pointer;
    text-decoration: none !important;
    height: 0px !important;
    color: white;
  }

  .loginPage {
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
}