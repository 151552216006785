.trendText2{
    font-size: 14px;
    color: #ffffff;
}

.followBtn_ {
    background: white;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    position: relative;
    /* Add relative positioning for the ::before pseudo-element */
    font-size: 16px;
    font-weight: bold;
    border: 0.5px solid white;
    width: 150px;
    justify-content: center;
    display: flex;
    padding: 5px 5px;
    border-radius: 1000px;
 }

@media screen and (max-width: 499px) {
    .rightbarContent {
        display: none;
    }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
    .rightbarContent {
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .rightbarContent {
        display: none;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .rightbarContent {
        display: none;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

    .rightbarContent {
        padding: 25px;
        width: 90%;
    }

    .trendsMainD {
 
    }

    .trends {
        background-color: #222b34;
        border-radius: 25px;
        max-width: 290px;
        max-height: 80vh;
        margin-top: 40px;
        padding: 20px;
        font-weight: bold;
        font-size: 18px;
        overflow: auto;
    }

    .trend {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        margin-top: 15px;
        padding: 10px;
        width: 90%;
        overflow: hidden;
    }

    .trend:hover {
        cursor: pointer;
        background-color: #2c353e;
    }

    .trend img {
        width: 70px;
        border-radius: 100%;
    }

    .trendText {
        font-size: 15px;
        font-weight: normal;
    }
}

@media screen and (min-width: 1920px) {

    .rightbarContent {
        padding: 25px;
        width: 90%;
    }

    .trends {
        background-color: #222b34;
        border-radius: 25px;
        max-width: 290px;
        max-height: 80vh;
        margin-top: 40px;
        padding: 20px;
        font-weight: bold;
        font-size: 18px;
        overflow: auto;
    }

    .trend {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        margin-top: 15px;
        padding: 10px;
        width: 90%;
        overflow: hidden;
    }

    .trend:hover {
        cursor: pointer;
        background-color: #2c353e;
    }

    .trend img {
        width: 70px;
        border-radius: 100%;
    }

    .trendText {
        font-size: 15px;
        font-weight: normal;
    }
}