@media screen and (max-width: 499px) {

    .profileBanner {
        width: 100%;
        max-width: 100%;
        height: 220px;
        object-fit: cover;
    }

    .pfpContainer {
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 1px solid #911d3a;
    }

    .profilePFP {
        width: 130px;
        border-radius: 50%;
        height: 130px;
        border: 5px solid #141d26;
        position: relative;
        top: -65px;
        left: 40px;
    }

    .profileName {
        color: white;
        position: relative;
        top: -55px;
        left: 40px;
        font-size: 20px;
        font-weight: bold;
    }

    .profileWallet {
        color: rgb(252, 153, 153);
        position: relative;
        top: -50px;
        left: 40px;
        font-size: 14px;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .profileBio {
        color: rgb(255, 255, 255);
        position: relative;
        top: -40px;
        left: 40px;
        font-size: 16px;
        font-weight: 500;
        width: 80%;
    }

    .profileEdit {
        color: white;
        position: relative;
        top: -215px;
        left: 230px;
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
    }

    .profileEdit:hover {
        cursor: pointer;
    }

    .profileTabs {
        display: flex;
        justify-content: center;
        color: white;
        font-weight: bold;
    }

    .profileTab {
        padding-bottom: 10px;
        border-bottom: 2px solid #860b2a;
    }

    .profileTabsMain {
        display: flex;
        justify-content: space-evenly;
    }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

    .profileBanner {
        width: 100%;
        max-width: 100%;
        height: 220px;
        object-fit: cover;
    }

    .pfpContainer {
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 1px solid #911d3a;
    }

    .profilePFP {
        width: 130px;
        border-radius: 50%;
        height: 130px;
        border: 5px solid #141d26;
        position: relative;
        top: -65px;
        left: 40px;
    }

    .profileName {
        color: white;
        position: relative;
        top: -55px;
        left: 40px;
        font-size: 20px;
        font-weight: bold;
    }

    .profileWallet {
        color: rgb(252, 153, 153);
        position: relative;
        top: -50px;
        left: 40px;
        font-size: 14px;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .profileBio {
        color: rgb(255, 255, 255);
        position: relative;
        top: -40px;
        left: 40px;
        font-size: 16px;
        font-weight: 500;
        width: 90%;
    }

    .profileEdit {
        color: white;
        position: relative;
        top: -220px;
        left: 320px;
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
    }

    .profileEdit:hover {
        cursor: pointer;
    }

    .profileTabs {
        display: flex;
        justify-content: center;
        color: white;
        font-weight: bold;
    }

    .profileTab {
        padding-bottom: 10px;
        border-bottom: 2px solid #860b2a;
    }

    .profileTabsMain {
        display: flex;
        justify-content: space-evenly;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

    .profileBanner {
        width: 100%;
        max-width: 100%;
        height: 220px;
        object-fit: cover;
    }

    .pfpContainer {
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 1px solid #911d3a;
    }

    .profilePFP {
        width: 130px;
        border-radius: 50%;
        height: 130px;
        border: 5px solid #141d26;
        position: relative;
        top: -65px;
        left: 40px;
    }

    .profileName {
        color: white;
        position: relative;
        top: -55px;
        left: 40px;
        font-size: 20px;
        font-weight: bold;
    }

    .profileWallet {
        color: rgb(252, 153, 153);
        position: relative;
        top: -50px;
        left: 40px;
        font-size: 14px;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .profileBio {
        color: rgb(255, 255, 255);
        position: relative;
        top: -40px;
        left: 40px;
        font-size: 16px;
        width: 90%;
        font-weight: 500;
    }

    .profileEdit {
        color: white;
        position: relative;
        top: -220px;
        left: 420px;
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
    }

    .profileEdit:hover {
        cursor: pointer;
    }

    .profileTabs {
        display: flex;
        justify-content: center;
        color: white;
        font-weight: bold;
    }

    .profileTab {
        padding-bottom: 10px;
        border-bottom: 2px solid #860b2a;
    }

    .profileTabsMain {
        display: flex;
        justify-content: space-evenly;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

    .profileBanner {
        width: 100%;
        max-width: 100%;
        height: 220px;
        object-fit: cover;
    }

    .pfpContainer {
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 1px solid #911d3a;
    }

    .profilePFP {
        width: 130px;
        border-radius: 50%;
        height: 130px;
        border: 5px solid #141d26;
        position: relative;
        top: -65px;
        left: 40px;
    }

    .profileName {
        color: white;
        position: relative;
        top: -55px;
        left: 40px;
        font-size: 20px;
        font-weight: bold;
    }

    .profileWallet {
        color: rgb(252, 153, 153);
        position: relative;
        top: -50px;
        left: 40px;
        font-size: 14px;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .profileBio {
        color: rgb(255, 255, 255);
        position: relative;
        top: -40px;
        left: 40px;
        font-size: 16px;
        font-weight: 500;
        width: 90%;

    }

    .profileEdit {
        color: white;
        position: relative;
        top: -220px;
        left: 520px;
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
    }

    .profileEdit:hover {
        cursor: pointer;
    }

    .profileTabs {
        display: flex;
        justify-content: center;
        color: white;
        font-weight: bold;
    }

    .profileTab {
        padding-bottom: 10px;
        border-bottom: 2px solid #860b2a;
    }

    .profileTabsMain {
        display: flex;
        justify-content: space-evenly;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

    .profileBanner {
        width: 100%;
        max-width: 100%;
        height: 220px;
        object-fit: cover;
    }

    .pfpContainer {
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 1px solid #911d3a;
    }

    .profilePFP {
        width: 130px;
        border-radius: 50%;
        height: 130px;
        border: 5px solid #141d26;
        position: relative;
        top: -65px;
        left: 40px;
    }

    .profileName {
        color: white;
        position: relative;
        top: -55px;
        left: 40px;
        font-size: 20px;
        font-weight: bold;
    }

    .profileWallet {
        color: rgb(252, 153, 153);
        position: relative;
        top: -50px;
        left: 40px;
        font-size: 14px;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .profileBio {
        color: rgb(255, 255, 255);
        position: relative;
        top: -40px;
        left: 40px;
        font-size: 16px;
        font-weight: 500;
        width: 90%;
    }

    .profileEdit {
        color: white;
        position: relative;
        top: -220px;
        left: 550px;
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
    }

    .profileEdit:hover {
        cursor: pointer;
    }

    .profileTabs {
        display: flex;
        justify-content: center;
        color: white;
        font-weight: bold;
    }

    .profileTab {
        padding-bottom: 10px;
        border-bottom: 2px solid #860b2a;
    }

    .profileTabsMain {
        display: flex;
        justify-content: space-evenly;
    }
}

@media screen and (min-width: 1920px) {
    
    .profileBanner {
        width: 100%;
        max-width: 100%;
        height: 220px;
        object-fit: cover;
    }

    .pfpContainer {
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 1px solid #911d3a;
    }

    .profilePFP {
        width: 130px;
        border-radius: 50%;
        height: 130px;
        border: 5px solid #141d26;
        position: relative;
        top: -65px;
        left: 40px;
    }

    .profileName {
        color: white;
        position: relative;
        top: -55px;
        left: 40px;
        font-size: 20px;
        font-weight: bold;
    }

    .profileWallet {
        color: rgb(252, 153, 153);
        position: relative;
        top: -50px;
        left: 40px;
        font-size: 14px;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .profileBio {
        color: rgb(255, 255, 255);
        position: relative;
        top: -40px;
        left: 40px;
        font-size: 16px;
        font-weight: 500;
        width: 90%;
    }

    .profileEdit {
        color: white;
        position: relative;
        top: -220px;
        left: 550px;
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
    }

    .profileEdit:hover {
        cursor: pointer;
    }

    .profileTabs {
        display: flex;
        justify-content: center;
        color: white;
        font-weight: bold;
    }

    .profileTab {
        padding-bottom: 10px;
        border-bottom: 2px solid #860b2a;
    }

    .profileTabsMain {
        display: flex;
        justify-content: space-evenly;
    }
}