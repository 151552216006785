.user--1 {
    margin-right: 20px;
    color: #fdfdfd;
}

.white {
    color: white;
}

.reNote2 {
    color: #a09e9e;
}

.interactionNums .hoverText {
    display: none;
    /* Initially hide the hoverText */
}

.interactionNums .initialText:hover+.hoverText {
    display: inline-block;
    /* Show hoverText when initialText is hovered */
}

.interactionNums .initialText:hover {
    display: none;
    /* Hide initialText when it's hovered */
}

.icon {
    margin-bottom: -4px;
    margin-right: 10px;
}

.reNote2:hover {
    text-decoration: underline;
    color: #c5c5c5;
}

.background:hover {
    background-color: #1c2834;
}

.loading {
    color: #222b34;
}

.nameDate {
    display: flex;
}

@media screen and (max-width: 499px) {

    .reNote {
        font-size: 14px;
        color: #a09e9e;
        margin-left: 40px;
        padding-top: 20px;
        margin-bottom: -30px;
    }

    /* Styles for the dropdown container */
    .dropdown {
        position: relative;
        display: inline-block;
    }

    /* Style for the "More" icon */
    .dropdown .More {
        cursor: pointer;
    }

    /* Styles for the dropdown content */
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #2b2b2b;
        min-width: 300px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
        border-radius: 10px;
        color: rgb(255, 255, 255);
    }

    /* Style for each dropdown item */
    .dropdown-content div {
        padding: 12px 16px;
        cursor: pointer;
    }

    /* Style for each dropdown item on hover */
    .dropdown-content div:hover {
        background-color: #353535;
    }

    /* Show the dropdown content when the dropdown container is clicked */
    .dropdown:hover .dropdown-content {
        display: block;
    }

    /* Adjust the position of the dropdown content */
    .dropdown-content {
        right: 0;
        /* Adjust as needed to position the menu properly */
        top: 100%;
        z-index: 100000;
        /* Position it just below the "More" icon */
    }

    /* Optional: Add a border to separate the dropdown items */
    .dropdown-content div:first-child {
        border-top: 1px solid #e1e8ed;
    }

    .avatar {
        font-size: 60px;
    }

    .FeedTweet {
        border-bottom: 1px solid #ac4f6681;
        padding: 40px;
        justify-content: space-between;
        display: flex;
        cursor: pointer;
        color: white;
        z-index: 1;
    }

    .FeedTweet:hover {
        background-color: #1c2834;
    }

    .completeTweet {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        width: 90%;
        margin-left: 15px;
    }

    .interactions {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        z-index: 4;
    }

    .interactionNums {
        color: #3f3f3f;
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        padding: 5px;
        border-radius: 1000px;
        z-index: 5;
        color: #b8b8b8;
    }

    .interactionNums2 {
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        border-radius: 1000px;
        z-index: 5;
        color: #b8b8b870;
        margin-top: auto;
        margin-bottom: auto;
        font-weight: lighter;
        font-size: 14px;
    }

    .interactionNums:hover {
        background-color: rgba(97, 0, 0, 0.815);
    }

    .who {
        display: flex;
        gap: 10px;
        font-weight: 600;
        align-items: center;
        justify-content: space-evenly;
    }

    .accWhen {
        font-size: 11px;
        font-weight: 400;
        color: gray;
    }

    .tweetContent {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 15px;

        z-index: 1;
        cursor: pointer;
    }

    .tweetImg {
        width: 98%;
        border-radius: 10px;
        height: 90%;
    }

    .loading {
        height: 60vh;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        columns: white;
    }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
    .reNote {
        font-size: 14px;
        color: #a09e9e;
        margin-left: 40px;
        padding-top: 20px;
        margin-bottom: -15px;
    }

    /* Styles for the dropdown container */
    .dropdown {
        position: relative;
        display: inline-block;
    }

    /* Style for the "More" icon */
    .dropdown .More {
        cursor: pointer;
    }

    /* Styles for the dropdown content */
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #2b2b2b;
        min-width: 400px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
        border-radius: 10px;
        color: rgb(255, 255, 255);
    }

    /* Style for each dropdown item */
    .dropdown-content div {
        padding: 12px 16px;
        cursor: pointer;
    }

    /* Style for each dropdown item on hover */
    .dropdown-content div:hover {
        background-color: #353535;
    }

    /* Show the dropdown content when the dropdown container is clicked */
    .dropdown:hover .dropdown-content {
        display: block;
    }

    /* Adjust the position of the dropdown content */
    .dropdown-content {
        right: 0;
        /* Adjust as needed to position the menu properly */
        top: 100%;
        z-index: 100000;
        /* Position it just below the "More" icon */
    }

    /* Optional: Add a border to separate the dropdown items */
    .dropdown-content div:first-child {
        border-top: 1px solid #e1e8ed;
    }

    .avatar {
        font-size: 60px;
    }

    .FeedTweet {
        max-width: 100%;
        border-bottom: 1px solid #ac4f6681;
        padding: 20px;
        justify-content: space-between;
        display: flex;
        cursor: pointer;
        color: white;
        z-index: 1;
    }

    .FeedTweet:hover {
        background-color: #1c2834;
    }

    .completeTweet {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        width: 90%;
        margin-left: 20px;
    }

    .interactions {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        z-index: 4;

    }

    .interactionNums {
        color: #3f3f3f;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        padding: 10px;
        border-radius: 1000px;
        z-index: 5;
        color: #b8b8b8;
    }

    .interactionNums2 {
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        border-radius: 1000px;
        z-index: 5;
        color: #b8b8b870;
        margin-top: auto;
        margin-bottom: auto;
        font-weight: lighter;
        font-size: 14px;
    }

    .interactionNums:hover {
        background-color: rgba(97, 0, 0, 0.815);
    }

    .who {
        display: flex;
        gap: 10px;
        font-weight: 600;
        align-items: center;
        justify-content: space-between;
    }

    .accWhen {
        font-size: 14px;
        font-weight: 400;
        color: gray;
    }

    .tweetContent {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 15px;

        z-index: 1;
        cursor: pointer;
    }

    .tweetImg {
        width: 98%;
        border-radius: 10px;
        height: 90%;
    }

    .loading {
        height: 60vh;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        columns: white;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .reNote {
        font-size: 14px;
        color: #a09e9e;
        margin-left: 40px;
        padding-top: 20px;
        margin-bottom: -15px;
    }

    /* Styles for the dropdown container */
    .dropdown {
        position: relative;
        display: inline-block;
    }

    /* Style for the "More" icon */
    .dropdown .More {
        cursor: pointer;
    }

    /* Styles for the dropdown content */
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #2b2b2b;
        min-width: 400px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
        border-radius: 10px;
        color: rgb(255, 255, 255);
    }

    /* Style for each dropdown item */
    .dropdown-content div {
        padding: 12px 16px;
        cursor: pointer;
    }

    /* Style for each dropdown item on hover */
    .dropdown-content div:hover {
        background-color: #353535;
    }

    /* Show the dropdown content when the dropdown container is clicked */
    .dropdown:hover .dropdown-content {
        display: block;
    }

    /* Adjust the position of the dropdown content */
    .dropdown-content {
        right: 0;
        /* Adjust as needed to position the menu properly */
        top: 100%;
        z-index: 100000;
        /* Position it just below the "More" icon */
    }

    /* Optional: Add a border to separate the dropdown items */
    .dropdown-content div:first-child {
        border-top: 1px solid #e1e8ed;
    }

    .avatar {
        font-size: 60px;
    }

    .FeedTweet {
        max-width: 100%;
        border-bottom: 1px solid #ac4f6681;
        padding: 20px;
        justify-content: space-between;
        display: flex;
        cursor: pointer;
        color: white;
        z-index: 1;
    }

    .FeedTweet:hover {
        background-color: #1c2834;
    }

    .completeTweet {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        width: 90%;
        margin-left: 20px;
    }

    .interactions {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        z-index: 4;

    }

    .interactionNums {
        color: #3f3f3f;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        padding: 10px;
        border-radius: 1000px;
        z-index: 5;
        color: #b8b8b8;
    }

    .interactionNums2 {
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        border-radius: 1000px;
        z-index: 5;
        color: #b8b8b870;
        margin-top: auto;
        margin-bottom: auto;
        font-weight: lighter;
        font-size: 14px;
    }

    .interactionNums:hover {
        background-color: rgba(97, 0, 0, 0.815);
    }

    .who {
        display: flex;
        gap: 10px;
        font-weight: 600;
        align-items: center;
        justify-content: space-between;
    }

    .accWhen {
        font-size: 15px;
        font-weight: 400;
        color: gray;
    }

    .tweetContent {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 15px;

        z-index: 1;
        cursor: pointer;
    }

    .tweetImg {
        width: 98%;
        border-radius: 10px;
        height: 90%;
    }

    .loading {
        height: 60vh;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        columns: white;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .reNote {
        font-size: 14px;
        color: #a09e9e;
        margin-left: 40px;
        padding-top: 20px;
        margin-bottom: -15px;
    }

    /* Styles for the dropdown container */
    .dropdown {
        position: relative;
        display: inline-block;
    }

    /* Style for the "More" icon */
    .dropdown .More {
        cursor: pointer;
    }

    /* Styles for the dropdown content */
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #2b2b2b;
        min-width: 400px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
        border-radius: 10px;
        color: rgb(255, 255, 255);
    }

    /* Style for each dropdown item */
    .dropdown-content div {
        padding: 12px 16px;
        cursor: pointer;
    }

    /* Style for each dropdown item on hover */
    .dropdown-content div:hover {
        background-color: #353535;
    }

    /* Show the dropdown content when the dropdown container is clicked */
    .dropdown:hover .dropdown-content {
        display: block;
    }

    /* Adjust the position of the dropdown content */
    .dropdown-content {
        right: 0;
        /* Adjust as needed to position the menu properly */
        top: 100%;
        z-index: 100000;
        /* Position it just below the "More" icon */
    }

    /* Optional: Add a border to separate the dropdown items */
    .dropdown-content div:first-child {
        border-top: 1px solid #e1e8ed;
    }

    .avatar {
        font-size: 60px;
    }

    .FeedTweet {
        max-width: 100%;
        border-bottom: 1px solid #ac4f6681;
        padding: 20px;
        justify-content: space-between;
        display: flex;
        cursor: pointer;
        color: white;
        z-index: 1;
    }

    .FeedTweet:hover {
        background-color: #1c2834;
    }

    .completeTweet {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        width: 90%;
        margin-left: 20px;
    }

    .interactions {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        z-index: 4;
    }

    .interactionNums {
        color: #3f3f3f;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        padding: 10px;
        border-radius: 1000px;
        z-index: 5;
        color: #b8b8b8;
    }

    .interactionNums2 {
        display: flex;
        gap: 5px;
        border-radius: 1000px;
        z-index: 5;
        color: #b8b8b870;
        margin-top: auto;
        margin-bottom: auto;
        font-weight: lighter;
        font-size: 14px;

    }

    .interactionNums:hover {
        background-color: rgba(97, 0, 0, 0.815);
    }

    .who {
        display: flex;
        gap: 10px;
        font-weight: 600;
        align-items: center;
        justify-content: space-between;
    }

    .accWhen {
        font-size: 15px;
        font-weight: 400;
        color: gray;
    }

    .tweetContent {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 15px;

        z-index: 1;
        cursor: pointer;
    }

    .tweetImg {
        width: 98%;
        border-radius: 10px;
        height: 90%;
    }

    .loading {
        height: 60vh;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        columns: white;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
    .reNote {
        font-size: 14px;
        color: #a09e9e;
        margin-left: 40px;
        padding-top: 20px;
        margin-bottom: -15px;
    }

    .MainTweet{
        background-color: #3f3f3f00;
        height: 200vh;
        overflow-y: scroll;    
    }
    

    /* Styles for the dropdown container */
    .dropdown {
        position: relative;
        display: inline-block;
    }

    /* Style for the "More" icon */
    .dropdown .More {
        cursor: pointer;
    }

    /* Styles for the dropdown content */
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #2b2b2b;
        min-width: 400px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
        border-radius: 10px;
        color: rgb(255, 255, 255);
    }

    /* Style for each dropdown item */
    .dropdown-content div {
        padding: 12px 16px;
        cursor: pointer;
    }

    /* Style for each dropdown item on hover */
    .dropdown-content div:hover {
        background-color: #353535;
    }

    /* Show the dropdown content when the dropdown container is clicked */
    .dropdown:hover .dropdown-content {
        display: block;
    }

    /* Adjust the position of the dropdown content */
    .dropdown-content {
        right: 0;
        /* Adjust as needed to position the menu properly */
        top: 100%;
        z-index: 100000;
        /* Position it just below the "More" icon */
    }

    /* Optional: Add a border to separate the dropdown items */
    .dropdown-content div:first-child {
        border-top: 1px solid #e1e8ed;
    }

    .avatar {
        font-size: 60px;
    }

    .FeedTweet {
        max-width: 100%;
        border-bottom: 1px solid #ac4f6681;
        padding: 20px;
        justify-content: space-between;
        display: flex;
        cursor: pointer;
        color: white;
        z-index: 1;
    }

    .FeedTweet:hover {
        background-color: #1c2834;
    }

    .completeTweet {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        width: 90%;
        margin-left: 20px;
    }

    .interactions {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        z-index: 4;

    }

    .interactionNums {
        color: #3f3f3f;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        padding: 10px;
        border-radius: 1000px;
        z-index: 5;
        color: #b8b8b8;
    }

    .interactionNums2 {
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        border-radius: 1000px;
        z-index: 5;
        color: #b8b8b870;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 14px;
        font-weight: lighter;
    }

    .interactionNums:hover {
        background-color: rgba(97, 0, 0, 0.815);
    }

    .who {
        display: flex;
        gap: 10px;
        font-weight: 600;
        align-items: center;
        justify-content: space-between;
    }

    .accWhen {
        font-size: 15px;
        font-weight: 400;
        color: gray;
    }

    .tweetContent {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 15px;
        z-index: 1;
        cursor: pointer;
        color: white;
    }

    .tweetImg {
        width: 98%;
        border-radius: 10px;
    }

    .loading {
        height: 60vh;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        columns: white;
    }
}

@media screen and (min-width: 1920px) {
    .reNote {
        font-size: 14px;
        color: #a09e9e;
        margin-left: 40px;
        padding-top: 20px;
        margin-bottom: -15px;
    }

    .MainTweet{
        background-color: #3f3f3f00;
        height: 200vh;
        overflow-y: scroll;    
    }
    

    /* Styles for the dropdown container */
    .dropdown {
        position: relative;
        display: inline-block;
    }

    /* Style for the "More" icon */
    .dropdown .More {
        cursor: pointer;
    }

    /* Styles for the dropdown content */
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #2b2b2b;
        min-width: 400px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
        border-radius: 10px;
        color: rgb(255, 255, 255);
    }

    /* Style for each dropdown item */
    .dropdown-content div {
        padding: 12px 16px;
        cursor: pointer;
    }

    /* Style for each dropdown item on hover */
    .dropdown-content div:hover {
        background-color: #353535;
    }

    /* Show the dropdown content when the dropdown container is clicked */
    .dropdown:hover .dropdown-content {
        display: block;
    }

    /* Adjust the position of the dropdown content */
    .dropdown-content {
        right: 0;
        /* Adjust as needed to position the menu properly */
        top: 100%;
        z-index: 100000;
        /* Position it just below the "More" icon */
    }

    /* Optional: Add a border to separate the dropdown items */
    .dropdown-content div:first-child {
        border-top: 1px solid #e1e8ed;
    }

    .avatar {
        font-size: 60px;
    }

    .FeedTweet {
        max-width: 100%;
        border-bottom: 1px solid #ac4f6681;
        padding: 20px;
        justify-content: space-between;
        display: flex;
        cursor: pointer;
        color: white;
        z-index: 1;
    }

    .FeedTweet:hover {
        background-color: #1c2834;
    }

    .completeTweet {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        width: 90%;
        margin-left: 20px;
    }

    .interactions {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        z-index: 4;

    }

    .interactionNums {
        color: #3f3f3f;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        padding: 10px;
        border-radius: 1000px;
        z-index: 5;
        color: #b8b8b8;
    }

    .interactionNums2 {
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        border-radius: 1000px;
        z-index: 5;
        color: #b8b8b870;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 14px;
        font-weight: lighter;
    }

    .interactionNums:hover {
        background-color: rgba(97, 0, 0, 0.815);
    }

    .who {
        display: flex;
        gap: 10px;
        font-weight: 600;
        align-items: center;
        justify-content: space-between;
    }

    .accWhen {
        font-size: 15px;
        font-weight: 400;
        color: gray;
    }

    .tweetContent {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 15px;
        z-index: 1;
        cursor: pointer;
        color: white;
    }

    .tweetImg {
        width: 98%;
        border-radius: 10px;
    }

    .loading {
        height: 60vh;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        columns: white;
    }
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    z-index: 1000;
    color: #000;
}

.popup h2 {
    margin-top: 0;
}

.close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}