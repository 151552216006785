.disMain {
    padding: 50px;
}

p {
    color: white;
}

div {
    color: white;
}

span {
    color: white;
}


.white2 {
    width: 90%;
    color: white;
    margin-left: auto;
    margin-right: auto;
}

ul.lst-kix_rfjhcxj9su94-1 {
    list-style-type: none
}

.lst-kix_rfjhcxj9su94-0>li:before {
    content: "\0025cf "
}

ul.lst-kix_rfjhcxj9su94-0 {
    list-style-type: none
}

.lst-kix_rfjhcxj9su94-1>li:before {
    content: "\0025cb "
}

ul.lst-kix_rfjhcxj9su94-5 {
    list-style-type: none
}

ul.lst-kix_rfjhcxj9su94-4 {
    list-style-type: none
}

ul.lst-kix_rfjhcxj9su94-3 {
    list-style-type: none
}

ul.lst-kix_rfjhcxj9su94-2 {
    list-style-type: none
}

.lst-kix_rfjhcxj9su94-4>li:before {
    content: "\0025cb "
}

ul.lst-kix_rfjhcxj9su94-8 {
    list-style-type: none
}

ul.lst-kix_rfjhcxj9su94-7 {
    list-style-type: none
}

ul.lst-kix_rfjhcxj9su94-6 {
    list-style-type: none
}

.lst-kix_rfjhcxj9su94-2>li:before {
    content: "\0025a0 "
}

.lst-kix_rfjhcxj9su94-3>li:before {
    content: "\0025cf "
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

.lst-kix_rfjhcxj9su94-8>li:before {
    content: "\0025a0 "
}

.lst-kix_rfjhcxj9su94-5>li:before {
    content: "\0025a0 "
}

.lst-kix_rfjhcxj9su94-6>li:before {
    content: "\0025cf "
}

.lst-kix_rfjhcxj9su94-7>li:before {
    content: "\0025cb "
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c12 {
    border-right-style: solid;
    padding: 6pt 6pt 6pt 6pt;
    border-bottom-color: #dddddd;
    border-top-width: 1.5pt;
    border-right-width: 1pt;
    border-left-color: #dddddd;
    vertical-align: top;
    border-right-color: #dddddd;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 208.5pt;
    border-top-color: #dddddd;
    border-bottom-style: solid
}

.c9 {
    border-right-style: solid;
    padding: 6pt 6pt 6pt 6pt;
    border-bottom-color: #dddddd;
    border-top-width: 0pt;
    border-right-width: 1pt;
    border-left-color: #dddddd;
    vertical-align: bottom;
    border-right-color: #dddddd;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1.5pt;
    width: 247.5pt;
    border-top-color: #000000;
    border-bottom-style: solid
}

.c20 {
    border-right-style: solid;
    padding: 6pt 6pt 6pt 6pt;
    border-bottom-color: #dddddd;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #dddddd;
    vertical-align: top;
    border-right-color: #dddddd;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 247.5pt;
    border-top-color: #dddddd;
    border-bottom-style: solid
}

.c13 {
    border-right-style: solid;
    padding: 6pt 6pt 6pt 6pt;
    border-bottom-color: #dddddd;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #dddddd;
    vertical-align: top;
    border-right-color: #dddddd;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 208.5pt;
    border-top-color: #dddddd;
    border-bottom-style: solid
}

.c22 {
    border-right-style: solid;
    padding: 6pt 6pt 6pt 6pt;
    border-bottom-color: #dddddd;
    border-top-width: 1.5pt;
    border-right-width: 1pt;
    border-left-color: #dddddd;
    vertical-align: top;
    border-right-color: #dddddd;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 247.5pt;
    border-top-color: #dddddd;
    border-bottom-style: solid
}

.c14 {
    border-right-style: solid;
    padding: 6pt 6pt 6pt 6pt;
    border-bottom-color: #dddddd;
    border-top-width: 0pt;
    border-right-width: 1pt;
    border-left-color: #dddddd;
    vertical-align: bottom;
    border-right-color: #dddddd;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1.5pt;
    width: 208.5pt;
    border-top-color: #000000;
    border-bottom-style: solid
}

.c16 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 8pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c5 {
    background-color: #ffffff00;
    padding-top: 17pt;
    padding-bottom: 8pt;
    line-height: 1.1;
    orphans: 2;
    widows: 2;
    text-align: left;
    color: white;
}

.c0 {
    background-color: #ffffff00;
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c3 {
    color: white;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 22.5pt;
    font-family: "Arial";
    font-style: normal
}

.c6 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

.c7 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13pt;
    font-family: "Arial";
    font-style: normal
}

.c1 {
    color: white;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10.5pt;
    font-family: "Arial";
    font-style: normal
}

.c2 {
    padding-top: 0pt;
    padding-bottom: 17pt;
    line-height: 1.6;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c19 {
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto
}

.c17 {
    font-size: 10.5pt;
    color: white;
    font-weight: 700
}

.c15 {
    background-color: #ffffff00;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt
}

.c21 {
    color: white;
    font-size: 10.5pt
}

.c10 {
    color: #3097d1;
    font-size: 10.5pt
}

.c11 {
    color: inherit;
    text-decoration: inherit
}

.c8 {
    padding: 0;
    margin: 0
}

.c18 {
    background-color: #ffffff
}

.c4 {
    height: 32.2pt
}

.title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}