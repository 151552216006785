.closeNote:hover,
.closeNote:focus {
    color: rgb(221, 32, 79);
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: white;
    font-size: 20px;
    padding: 5px;
    border-radius: 50%;
    background-color: #1c2834;
}


.tweetImg {
    width: 98%;
    border-radius: 10px;
    margin-top: 10px;
}

.who4 {
    display: flex;
    font-weight: 600;
    justify-content: space-between;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.who4Sticky {
    position: sticky;
    bottom: 0;
}

.user--1New {
    margin-top: 10px;
    color: white;
    font-style: lighter;
    font-size: 14px;
}

@media screen and (max-width: 499px) {

    .closeNote {
        color: rgba(255, 255, 255, 0.808);
        font-size: 20px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
        position: sticky;
    }

    .closeNote {
        position: absolute;
        right: 0;
        top: 5%;
        color: rgba(255, 255, 255, 0.836);
        font-size: 20px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
    }

    .sendMsg {
        position: sticky;
        bottom: 0;
        display: flex;
        background-color: #eff3f4;
        border-radius: 10px;
        justify-content: flex-start;
        flex-flow: column nowrap;
    }

    .sendMsg2 {
        position: sticky;
        bottom: 0;
        display: flex;
        background-color: #a7a7a7;
        border-radius: 10px;
        justify-content: flex-start;
        flex-flow: column nowrap;
    }

    .imgDiv2 {
        border-radius: 100%;
        padding: 5px;
        display: flex;

    }


    .typeMsg {
        width: 80%;
        border-radius: 10px;
        padding: 15px;
        border: none;
        background-color: #1c283400;
    }

    .send {
        margin-left: 20px;
    }

    .user--1 {
        font-size: 14px;
    }

    .cont {
        font-size: 14px;
    }

    .formMain2 {
        transform: translate(-50%, -50%);
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.534);
    }

    .formMain2Mobile {
        transform: translate(-50%, -50%);
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.534);
    }

    .formMain-2 {
        transform: translate(-50%, -50%);
        position: fixed;
        width: fit-content;
        height: fit-content;
        top: 50%;
        left: 50%;
        z-index: 1;
        overflow: hidden;
        z-index: 100000;
        display: flex;
        flex-flow: row nowrap;
        background-color: #1c2834;
        padding: 30px;
        box-shadow: rgba(92, 0, 0, 0.808) 0px 3px 8px;
        border-radius: 20px;
        width: 90%;
        margin-top: 50px;
    }

    .formMain-2Mob {
        transform: translate(-50%, -50%);
        position: fixed;
        width: fit-content;
        height: 60vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        overflow: hidden;
        z-index: 100000;
        display: flex;
        flex-flow: column nowrap;
        background-color: #1c2834;
        padding: 30px;
        box-shadow: rgba(92, 0, 0, 0.808) 0px 3px 8px;
        border-radius: 20px;
        width: 90%;
        margin-top: 50px;
    }

    .completeTweet2 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-left: 20px;
    }

    .senderAndRec {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .msgs {
        width: 100%;
        height: 80vh;
        padding: 10px;
        overflow-y: scroll;
        background-color: #460415;
    }

    .msgsMobile {
        width: 100%;
        height: 80vh;
        padding: 10px;
        overflow-y: scroll;
        z-index: 5;
        background-color: #460415;

    }

    .msgCon {
        color: white;
        text-align: left;
        width: 50%;
        margin-left: auto;
        background-color: rgba(168, 25, 61, 0.541);
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 40px;
        display: flex;
        flex-flow: column wrap;
    }

    .msgCon2 {
        color: rgb(68, 68, 68);
        text-align: left;
        width: 50%;
        margin-right: auto;
        background-color: rgb(189, 200, 211);
        padding: 10px;
        border-radius: 10px;
        display: flex;
        flex-flow: column wrap;
        margin-left: 20px;
        margin-bottom: 40px;

    }

    .who2 {
        display: flex;
        gap: 10px;
        border-right: #ac4f6681 0.5px solid;
        width: 50%;
        flex-flow: column;
        height: 80vh;
        padding-right: 20px;
    }

    .who2Mob {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        border-right: #ac4f6681 0.5px solid;
        width: 30%;
        flex-flow: column;
        height: 80vh;
        padding-right: 20px;
    }

    .who3 {
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        width: fit-content;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
    }

    .who3_ {
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        width: fit-content;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        background-color: #460415;
        width: 105%;
    }

    .senders {
        height: fit-content;
    }

    .day {
        color: grey;
        font-size: 10px;
        margin-top: 10px;
    }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

    .closeNote {
        color: rgba(255, 255, 255, 0.808);
        font-size: 20px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
        position: sticky;
    }

    .sendMsg {
        position: sticky;
        bottom: 0;
        display: flex;
        background-color: #eff3f4;
        border-radius: 10px;
        justify-content: flex-start;
        flex-flow: column nowrap;
    }

    .sendMsg2 {
        position: sticky;
        bottom: 0;
        display: flex;
        background-color: #a7a7a7;
        border-radius: 10px;
        justify-content: flex-start;
        flex-flow: column nowrap;
    }

    .imgDiv2 {
        border-radius: 100%;
        padding: 5px;
        display: flex;

    }


    .typeMsg {
        width: 50%;
        border-radius: 10px;
        padding: 15px;
        border: none;
        background-color: #1c283400;
    }

    .send {
        margin-left: 20px;
    }

    .formMain2 {
        transform: translate(-50%, -50%);
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.534);
    }

    .formMain-2 {
        transform: translate(-50%, -50%);
        position: fixed;
        width: fit-content;
        height: fit-content;
        top: 50%;
        left: 50%;
        z-index: 1;
        overflow: hidden;
        z-index: 100000;
        display: flex;
        flex-flow: row nowrap;
        background-color: #1c2834;
        padding: 30px;
        box-shadow: rgba(92, 0, 0, 0.808) 0px 3px 8px;
        border-radius: 20px;
        width: 90%;
        margin-top: 50px;
    }

    .completeTweet2 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-left: 20px;
    }

    .senderAndRec {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .msgs {
        width: 100%;
        height: 80vh;
        padding: 10px;
        overflow-y: scroll;
        background-color: #460415;
    }

    .msgCon {
        color: white;
        text-align: left;
        width: 50%;
        margin-left: auto;
        background-color: rgba(168, 25, 61, 0.541);
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 40px;
        display: flex;
        flex-flow: column wrap;
        width: 80%;

    }

    .msgCon2 {
        color: rgb(68, 68, 68);
        text-align: left;
        width: 50%;
        margin-right: auto;
        background-color: rgb(189, 200, 211);
        padding: 10px;
        border-radius: 10px;
        display: flex;
        flex-flow: column wrap;
        margin-left: 20px;
        margin-bottom: 40px;
        width: 80%;

    }

    .who2 {
        display: flex;
        gap: 10px;
        border-right: #ac4f6681 0.5px solid;
        width: 30%;
        flex-flow: column;
        height: 80vh;
        padding-right: 20px;
    }

    .who3 {
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        width: fit-content;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        width: 95%;
        background-color: #8089921f;
    }

    .who3_ {
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        width: fit-content;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        width: 105%;
        background-color: #460415;
    }

    .senders {
        height: fit-content;
    }

    .day {
        color: grey;
        font-size: 12px;
        margin-top: 10px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

    .closeNote {
        color: rgba(255, 255, 255, 0.808);
        font-size: 20px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
        position: sticky;
    }

    .sendMsg {
        position: sticky;
        bottom: 0;
        display: flex;
        background-color: #eff3f4;
        border-radius: 10px;
        justify-content: space-between;
    }

    .sendMsg2 {
        position: sticky;
        bottom: 0;
        display: flex;
        background-color: #a7a7a7;
        border-radius: 10px;
        justify-content: space-between;
    }

    .imgDiv2 {
        border-radius: 100%;
        padding: 10px;
        margin-left: 80px;
        display: flex;

    }


    .typeMsg {
        width: 50%;
        border-radius: 10px;
        padding: 15px;
        border: none;
        background-color: #1c283400;
    }

    .send {
        margin-left: 20px;
    }

    .formMain2 {
        transform: translate(-50%, -50%);
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.534);

    }

    .formMain-2 {
        transform: translate(-50%, -50%);
        position: fixed;
        width: fit-content;
        height: 80vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        overflow: hidden;
        z-index: 100000;
        display: flex;
        flex-flow: row nowrap;
        background-color: #1c2834;
        padding: 30px;
        box-shadow: rgba(92, 0, 0, 0.808) 0px 3px 8px;
        border-radius: 20px;
        width: 90%;
    }

    .completeTweet2 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-left: 20px;
    }

    .senderAndRec {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .msgs {
        width: 100%;
        height: 80vh;
        padding: 10px;
        overflow-y: scroll;
        background-color: #460415;

    }


    .msgCon {
        color: white;
        text-align: left;
        width: 80%;
        margin-left: auto;
        background-color: rgba(168, 25, 61, 0.541);
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 40px;
        display: flex;
        flex-flow: column wrap;
    }

    .msgCon2 {
        color: rgb(68, 68, 68);
        text-align: left;
        width: 80%;
        margin-right: auto;
        background-color: rgb(189, 200, 211);
        padding: 10px;
        border-radius: 10px;
        display: flex;
        flex-flow: column wrap;
        margin-left: 20px;
        margin-bottom: 40px;

    }

    .who2 {
        display: flex;
        gap: 10px;
        border-right: #ac4f6681 0.5px solid;
        width: 30%;
        flex-flow: column;
        height: 80vh;
        padding-right: 20px;
    }

    .who3 {
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        background-color: #8089921f;
        width: fit-content;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        margin-right: 25px;
        width: 95%;
    }

    .who3_ {
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        background-color: #460415;
        width: fit-content;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        margin-right: 25px;
        width: 105%;
    }

    .senders {
        height: fit-content;
    }

    .day {
        color: grey;
        font-size: 12px;
        margin-top: 10px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .closeNote {
        color: rgba(255, 255, 255, 0.808);
        font-size: 20px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
        position: sticky;

    }

    .sendMsg {
        position: sticky;
        bottom: 0;
        display: flex;
        background-color: #eff3f4;
        border-radius: 10px;
        justify-content: space-between;
    }

    .sendMsg2 {
        position: sticky;
        bottom: 0;
        display: flex;
        background-color: #a7a7a7;
        border-radius: 10px;
        justify-content: space-between;
    }

    .imgDiv2 {
        border-radius: 100%;
        padding: 10px;
        margin-left: 80px;
        display: flex;

    }


    .typeMsg {
        width: 50%;
        border-radius: 10px;
        padding: 15px;
        border: none;
        background-color: #1c283400;
    }

    .send {
        margin-left: 20px;
    }

    .formMain2 {
        transform: translate(-50%, -50%);
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.534);

    }

    .formMain-2 {
        transform: translate(-50%, -50%);
        position: fixed;
        width: fit-content;
        height: 80vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        overflow: hidden;
        z-index: 100000;
        display: flex;
        flex-flow: row nowrap;
        background-color: #1c2834;
        padding: 30px;
        box-shadow: rgba(92, 0, 0, 0.808) 0px 3px 8px;
        border-radius: 20px;
        width: 90%;
    }

    .completeTweet2 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-left: 20px;
    }

    .senderAndRec {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .msgs {
        width: 100%;
        height: 80vh;
        padding: 10px;
        overflow-y: scroll;
        background-color: #460415;

    }


    .msgCon {
        color: white;
        text-align: left;
        width: 50%;
        margin-left: auto;
        background-color: rgba(168, 25, 61, 0.541);
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 40px;
        display: flex;
        flex-flow: column wrap;
    }

    .msgCon2 {
        color: rgb(68, 68, 68);
        text-align: left;
        width: 50%;
        margin-right: auto;
        background-color: rgb(189, 200, 211);
        padding: 10px;
        border-radius: 10px;
        display: flex;
        flex-flow: column wrap;
        margin-left: 20px;
        margin-bottom: 40px;

    }

    .who2 {
        display: flex;
        gap: 10px;
        border-right: #ac4f6681 0.5px solid;
        width: 30%;
        flex-flow: column;
        height: 80vh;
        padding-right: 20px;
    }


    .closeNote {
        position: absolute;
        right: 0;
        top: 5%;
        color: rgba(255, 255, 255, 0.836);
        font-size: 20px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
    }

    .who3 {
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        background-color: #8089921f;
        width: fit-content;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        width: 95%;
    }

    .who3_ {
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        background-color: #460415;
        width: fit-content;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        width: 105%;
    }

    .senders {
        height: fit-content;
    }

    .day {
        color: grey;
        font-size: 12px;
        margin-top: 10px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

    .closeNote {
        color: rgba(255, 255, 255, 0.808);
        font-size: 20px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
        position: sticky;
     }

    .sendMsg {
        position: sticky;
        bottom: 0;
        display: flex;
        background-color: #eff3f4;
        border-radius: 10px;
        justify-content: space-between;
    }

    .sendMsg2 {
        position: sticky;
        bottom: 0;
        display: flex;
        background-color: #a7a7a7;
        border-radius: 10px;
        justify-content: space-between;
    }

    .imgDiv2 {
        border-radius: 100%;
        padding: 10px;
        margin-left: 80px;
        display: flex;
    }

    .typeMsg {
        width: 50%;
        border-radius: 10px;
        padding: 15px;
        border: none;
        background-color: #1c283400;
    }

    .send {
        margin-left: 20px;
    }

    .formMain2 {
        transform: translate(-50%, -50%);
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.534);

    }

    .formMain-2 {
        transform: translate(-50%, -50%);
        position: fixed;
        width: fit-content;
        height: 80vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        overflow: hidden;
        z-index: 100000;
        display: flex;
        flex-flow: row nowrap;
        background-color: #1c2834;
        padding: 30px;
        box-shadow: rgba(92, 0, 0, 0.808) 0px 3px 8px;
        border-radius: 20px;
        width: 90%;
    }

    .completeTweet2 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-left: 20px;
    }

    .senderAndRec {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .msgs {
        width: 100%;
        height: 80vh;
        padding: 10px;
        overflow-y: scroll;
        background-color: #460415;
    }


    .msgCon {
        color: white;
        text-align: left;
        width: 50%;
        margin-left: auto;
        /*background-color: rgba(168, 25, 61, 0.541);*/
        background-color: rgba(168, 25, 61, 0.541);
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 40px;
        display: flex;
        flex-flow: column wrap;
    }

    .msgCon2 {
        color: rgb(68, 68, 68);
        text-align: left;
        width: 50%;
        margin-right: auto;
        background-color: rgb(189, 200, 211);
        padding: 10px;
        border-radius: 10px;
        display: flex;
        flex-flow: column wrap;
        margin-left: 20px;
        margin-bottom: 40px;

    }

    .who2 {
        display: flex;
        gap: 10px;
        border-right: #ac4f6681 0.5px solid;
        width: 30%;
        flex-flow: column;
        height: 80vh;
        padding-right: 20px;
    }

    .who3 {
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        background-color: #8089921f;
        width: fit-content;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        width: 95%;
    }

    .who3_ {
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        background-color: #460415;
        width: fit-content;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        width: 105%;
    }

    .senders {
        height: fit-content;
    }

    .day {
        color: grey;
        font-size: 12px;
        margin-top: 10px;
    }
}

@media screen and (min-width: 1920px) {

    .closeNote {
        color: rgba(255, 255, 255, 0.808);
        font-size: 20px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
        position: sticky;
    }

    .closeNote {
        position: absolute;
        right: 0;
        top: 5%;
        color: rgba(255, 255, 255, 0.836);
        font-size: 25px;
        font-weight: bold;
        background-color: rgba(172, 67, 67, 0);
        height: fit-content;
        cursor: pointer;
        border: none;
        transition: transform .2s ease-in-out;
    }

    .sendMsg {
        position: sticky;
        bottom: 0;
        display: flex;
        background-color: #eff3f4;
        border-radius: 10px;
        justify-content: space-between;
    }

    .sendMsg2 {
        position: sticky;
        bottom: 0;
        display: flex;
        background-color: #a7a7a7;
        border-radius: 10px;
        justify-content: space-between;
    }

    .imgDiv2 {
        border-radius: 100%;
        padding: 10px;
        margin-left: 80px;
        display: flex;

    }


    .typeMsg {
        width: 50%;
        border-radius: 10px;
        padding: 15px;
        border: none;
        background-color: #1c283400;
    }

    .send {
        margin-left: 20px;
    }


    .formMain2 {
        transform: translate(-50%, -50%);
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.534);

    }

    .formMain-2 {
        transform: translate(-50%, -50%);
        position: fixed;
        width: fit-content;
        height: 80vh;
        top: 50%;
        left: 50%;
        z-index: 1;
        overflow: hidden;
        z-index: 100000;
        display: flex;
        flex-flow: row nowrap;
        background-color: #1c2834;
        padding: 30px;
        box-shadow: rgba(92, 0, 0, 0.808) 0px 3px 8px;
        border-radius: 20px;
        width: 90%;
    }

    .completeTweet2 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-left: 20px;
    }

    .senderAndRec {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .msgs {
        width: 100%;
        height: 80vh;
        padding: 10px;
        overflow-y: scroll;
        background-color: #460415;
    }


    .msgCon {
        color: white;
        text-align: left;
        width: 50%;
        margin-left: auto;
        background-color: rgba(168, 25, 61, 0.541);
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 40px;
        display: flex;
        flex-flow: column wrap;
    }

    .msgCon2 {
        color: rgb(68, 68, 68);
        text-align: left;
        width: 50%;
        margin-right: auto;
        background-color: rgb(189, 200, 211);
        padding: 10px;
        border-radius: 10px;
        display: flex;
        flex-flow: column wrap;
        margin-left: 20px;
        margin-bottom: 40px;

    }

    .who2 {
        display: flex;
        gap: 10px;
        border-right: #ac4f6681 0.5px solid;
        width: 30%;
        flex-flow: column;
        height: 80vh;
        padding-right: 20px;
    }

    .who3 {
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        background-color: #8089921f;
        width: fit-content;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        width: 95%;
    }

    .who3_ {
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        background-color: #460415;
        width: fit-content;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        width: 105%;
    }

    .senders {
        height: fit-content;
    }

    .day {
        color: grey;
        font-size: 12px;
        margin-top: 10px;
    }
}