.icons2 {
    font-size: 25px;
}

@media screen and (max-width: 499px) {

    .more {
        margin-top: 12px;
        margin-right: 15px;
        cursor: pointer;
    }

    .profileBanner {
        width: 100%;
        max-width: 90%;
        height: 220px;
        object-fit: cover;
    }

    .pfpContainer {
        max-width: 95%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 1px solid #ac4f6681;
        margin-left: auto;
        margin-right: auto;
    }

    .profilePFP {
        width: 100px;
        border-radius: 50%;
        height: 100px;
        border: 5px solid #141d26;
        position: relative;
        top: -65px;
        left: 40px;
    }

    .profileName {
        color: white;
        position: relative;
        top: -55px;
        left: 40px;
        font-size: 20px;
        font-weight: bold;
    }

    .profileWallet {
        color: rgb(114, 114, 114);
        position: relative;
        top: -50px;
        left: 40px;
        font-size: 14px;
        margin-right: 50px;
    }

    .profileBio {
        color: white;
        position: relative;
        top: -40px;
        left: 40px;
        font-size: 16px;
        font-weight: 500;
        width: 80%;
    }

    .followBtn {
        color: white;
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
        margin-right: 60px;
        cursor: pointer;
    }

    .followBtn2 {
        background: white;
        color: black;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        position: relative;
        /* Add relative positioning for the ::before pseudo-element */
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
        margin-right: 60px;
    }

    .followBtn2:hover::before {
        content: "Unfollow";
        /* Replace the content with "Unfollow" when hovering */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: grey;
        color: white;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
    }


    .followBtn2:hover::before {
        content: "Unfollow";
        /* Replace the content with "Unfollow" when hovering */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: grey;
        color: white;
    }


    .followDiv2 {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .following:hover {
        background: grey;
        /* Keep the background color the same when hovering */
        color: white;
    }

    .following:hover::before {
        content: "Unfollow";
        /* Add "Unfollow" text when hovering over "Following" */
        position: absolute;
    }

    .followDiv {
        display: flex;
        justify-content: space-between;
    }

    .profileEdit:hover {
        cursor: pointer;
    }

    .profileTabs {
        display: flex;
        justify-content: center;
        color: white;
        font-weight: bold;
    }

    .profileTab {
        padding-bottom: 10px;
        border-bottom: 2px solid #860b2a;
    }

    .flexDiv {
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
    }
}

@media screen and (min-width: 500px) and (max-width: 767px) {


    .more {
        margin-top: 12px;
        margin-left: 10px;
        cursor: pointer;
    }

    .profileBanner {
        width: 100%;
        max-width: 100%;
        height: 220px;
        object-fit: cover;
    }

    .pfpContainer {
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 1px solid #ac4f6681;
    }

    .profilePFP {
        width: 130px;
        border-radius: 50%;
        height: 130px;
        border: 5px solid #141d26;
        position: relative;
        top: -65px;
        left: 40px;
    }

    .profileName {
        color: white;
        position: relative;
        top: -55px;
        left: 40px;
        font-size: 20px;
        font-weight: bold;
    }

    .profileWallet {
        color: rgb(114, 114, 114);
        position: relative;
        top: -50px;
        left: 40px;
        font-size: 14px;
        margin-right: 50px;
    }

    .profileBio {
        color: white;
        position: relative;
        top: -40px;
        left: 40px;
        font-size: 16px;
        font-weight: 500;
        width: 90%;
    }

    .followBtn {
        color: white;
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
        margin-left: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .followBtn2 {
        background: white;
        color: black;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        position: relative;
        /* Add relative positioning for the ::before pseudo-element */
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .followBtn2:hover::before {
        content: "Unfollow";
        /* Replace the content with "Unfollow" when hovering */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: grey;
        color: white;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
    }


    .followBtn2:hover::before {
        content: "Unfollow";
        /* Replace the content with "Unfollow" when hovering */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: grey;
        color: white;
    }


    .followDiv2 {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .following:hover {
        background: grey;
        /* Keep the background color the same when hovering */
        color: white;
    }

    .following:hover::before {
        content: "Unfollow";
        /* Add "Unfollow" text when hovering over "Following" */
        position: absolute;
    }

    .followDiv {
        display: flex;
        justify-content: space-between;
    }

    .profileEdit:hover {
        cursor: pointer;
    }

    .profileTabs {
        display: flex;
        justify-content: center;
        color: white;
        font-weight: bold;
    }

    .profileTab {
        padding-bottom: 10px;
        border-bottom: 2px solid #860b2a;
    }

    .flexDiv {
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

    .more {
        margin-top: 12px;
        margin-left: 10px;
        cursor: pointer;
    }

    .profileBanner {
        width: 100%;
        max-width: 100%;
        height: 220px;
        object-fit: cover;
    }

    .pfpContainer {
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 1px solid #ac4f6681;
    }

    .profilePFP {
        width: 130px;
        border-radius: 50%;
        height: 130px;
        border: 5px solid #141d26;
        position: relative;
        top: -65px;
        left: 40px;
    }

    .profileName {
        color: white;
        position: relative;
        top: -55px;
        left: 40px;
        font-size: 20px;
        font-weight: bold;
    }

    .profileWallet {
        color: rgb(114, 114, 114);
        position: relative;
        top: -50px;
        left: 40px;
        font-size: 14px;
        margin-right: 50px;
    }

    .profileBio {
        color: white;
        position: relative;
        top: -40px;
        left: 40px;
        font-size: 16px;
        font-weight: 500;
        width: 90%;
    }

    .followBtn {
        color: white;
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
        margin-left: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .followBtn2 {
        background: white;
        color: black;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        position: relative;
        /* Add relative positioning for the ::before pseudo-element */
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .followBtn2:hover::before {
        content: "Unfollow";
        /* Replace the content with "Unfollow" when hovering */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: grey;
        color: white;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
    }


    .followBtn2:hover::before {
        content: "Unfollow";
        /* Replace the content with "Unfollow" when hovering */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: grey;
        color: white;
    }


    .followDiv2 {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .following:hover {
        background: grey;
        /* Keep the background color the same when hovering */
        color: white;
    }

    .following:hover::before {
        content: "Unfollow";
        /* Add "Unfollow" text when hovering over "Following" */
        position: absolute;
    }

    .followDiv {
        display: flex;
        justify-content: space-between;
    }

    .profileEdit:hover {
        cursor: pointer;
    }

    .profileTabs {
        display: flex;
        justify-content: center;
        color: white;
        font-weight: bold;
    }

    .profileTab {
        padding-bottom: 10px;
        border-bottom: 2px solid #860b2a;
    }

    .flexDiv {
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

    .more {
        margin-top: 12px;
        margin-left: 10px;
        cursor: pointer;
    }

    .profileBanner {
        width: 100%;
        max-width: 100%;
        height: 220px;
        object-fit: cover;
    }

    .pfpContainer {
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 1px solid #ac4f6681;
    }

    .profilePFP {
        width: 130px;
        border-radius: 50%;
        height: 130px;
        border: 5px solid #141d26;
        position: relative;
        top: -65px;
        left: 40px;
    }

    .profileName {
        color: white;
        position: relative;
        top: -55px;
        left: 40px;
        font-size: 20px;
        font-weight: bold;
    }

    .profileWallet {
        color: rgb(114, 114, 114);
        position: relative;
        top: -50px;
        left: 40px;
        font-size: 14px;
        margin-right: 50px;
    }

    .profileBio {
        color: white;
        position: relative;
        top: -40px;
        left: 40px;
        font-size: 16px;
        font-weight: 500;
        width: 90%;
    }

    .followBtn {
        color: white;
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
        margin-left: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .followBtn2 {
        background: white;
        color: black;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        position: relative;
        /* Add relative positioning for the ::before pseudo-element */
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .followBtn2:hover::before {
        content: "Unfollow";
        /* Replace the content with "Unfollow" when hovering */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: grey;
        color: white;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
    }


    .followBtn2:hover::before {
        content: "Unfollow";
        /* Replace the content with "Unfollow" when hovering */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: grey;
        color: white;
    }


    .followDiv2 {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .following:hover {
        background: grey;
        /* Keep the background color the same when hovering */
        color: white;
    }

    .following:hover::before {
        content: "Unfollow";
        /* Add "Unfollow" text when hovering over "Following" */
        position: absolute;
    }


    .followDiv {
        display: flex;
        justify-content: space-between;
    }

    .profileEdit:hover {
        cursor: pointer;
    }

    .profileTabs {
        display: flex;
        justify-content: center;
        color: white;
        font-weight: bold;
    }

    .profileTab {
        padding-bottom: 10px;
        border-bottom: 2px solid #860b2a;
    }

    .flexDiv {
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

    .more {
        margin-top: 12px;
        margin-left: 10px;
        cursor: pointer;
    }

    .profileBanner {
        width: 100%;
        max-width: 100%;
        height: 220px;
        object-fit: cover;
    }

    .pfpContainer {
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 1px solid #ac4f6681;
    }

    .profilePFP {
        width: 130px;
        border-radius: 50%;
        height: 130px;
        border: 5px solid #141d26;
        position: relative;
        top: -65px;
        left: 40px;
    }

    .profileName {
        color: white;
        position: relative;
        top: -55px;
        left: 40px;
        font-size: 20px;
        font-weight: bold;
    }

    .profileWallet {
        color: rgb(114, 114, 114);
        position: relative;
        top: -50px;
        left: 40px;
        font-size: 14px;
        margin-right: 50px;
    }

    .profileBio {
        color: white;
        position: relative;
        top: -40px;
        left: 40px;
        font-size: 16px;
        font-weight: 500;
        width: 90%;
    }

    .followBtn {
        color: white;
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
        margin-left: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .followBtn2 {
        background: white;
        color: black;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        position: relative;
        /* Add relative positioning for the ::before pseudo-element */
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .followBtn2:hover::before {
        content: "Unfollow";
        /* Replace the content with "Unfollow" when hovering */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: grey;
        color: white;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
    }


    .followBtn2:hover::before {
        content: "Unfollow";
        /* Replace the content with "Unfollow" when hovering */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: grey;
        color: white;
    }


    .followDiv2 {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .following:hover {
        background: grey;
        /* Keep the background color the same when hovering */
        color: white;
    }

    .following:hover::before {
        content: "Unfollow";
        /* Add "Unfollow" text when hovering over "Following" */
        position: absolute;
    }

    .followDiv {
        display: flex;
        justify-content: space-between;
    }

    .profileEdit:hover {
        cursor: pointer;
    }

    .profileTabs {
        display: flex;
        justify-content: center;
        color: white;
        font-weight: bold;
    }

    .profileTab {
        padding-bottom: 10px;
        border-bottom: 2px solid #860b2a;
    }

    .flexDiv {
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
    }
}

@media screen and (min-width: 1920px) {


    .more {
        margin-top: 12px;
        margin-left: 10px;
        cursor: pointer;
    }

    .profileBanner {
        width: 100%;
        max-width: 100%;
        height: 220px;
        object-fit: cover;
    }

    .pfpContainer {
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        border-bottom: 1px solid #ac4f6681;
    }

    .profilePFP {
        width: 130px;
        border-radius: 50%;
        height: 130px;
        border: 5px solid #141d26;
        position: relative;
        top: -65px;
        left: 40px;
    }

    .profileName {
        color: white;
        position: relative;
        top: -55px;
        left: 40px;
        font-size: 20px;
        font-weight: bold;
    }

    .profileWallet {
        color: rgb(114, 114, 114);
        position: relative;
        top: -50px;
        left: 40px;
        font-size: 14px;
        margin-right: 50px;
    }

    .profileBio {
        color: white;
        position: relative;
        top: -40px;
        left: 40px;
        font-size: 16px;
        font-weight: 500;
        width: 90%;
    }

    .followBtn {
        color: white;
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
        margin-left: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .followBtn2 {
        background: white;
        color: black;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        position: relative;
        /* Add relative positioning for the ::before pseudo-element */
        font-size: 16px;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .followBtn2:hover::before {
        content: "Unfollow";
        /* Replace the content with "Unfollow" when hovering */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: grey;
        color: white;
        font-weight: bold;
        border: 0.5px solid white;
        width: 100px;
        justify-content: center;
        display: flex;
        padding: 10px 10px;
        border-radius: 1000px;
    }


    .followBtn2:hover::before {
        content: "Unfollow";
        /* Replace the content with "Unfollow" when hovering */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: grey;
        color: white;
    }


    .followDiv2 {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .following:hover {
        background: grey;
        /* Keep the background color the same when hovering */
        color: white;
    }

    .following:hover::before {
        content: "Unfollow";
        /* Add "Unfollow" text when hovering over "Following" */
        position: absolute;
    }

    .followDiv {
        display: flex;
        justify-content: space-between;
    }

    .profileEdit:hover {
        cursor: pointer;
    }

    .profileTabs {
        display: flex;
        justify-content: center;
        color: white;
        font-weight: bold;
    }

    .profileTab {
        padding-bottom: 10px;
        border-bottom: 2px solid #860b2a;
    }

    .flexDiv {
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
    }
}